import { ReactComponent as MempoolsLogo } from '@/assets/icons/mempools-logo-light.svg'
import { Box, Button, Typography } from '@mui/material'
import Link from 'next/link'

export default function ErrorPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        background:
          'radial-gradient(100% 99.99999767293079% at 49.999997019962585% 7.666410839490823e-7%,#641a1a 0%,rgba(35,11,43,1) 52.48328447341919%,rgba(0,0,0,1) 100%)',
      }}
    >
      <Box
        sx={{
          marginBottom: '20px',
        }}
      >
        <Link href={'/'}>
          <MempoolsLogo />
        </Link>
      </Box>
      <Typography
        sx={{
          backgroundImage: 'linear-gradient(0deg, rgb(222, 204, 229) 0%, rgb(240, 194, 195) 100%)',
          backgroundClip: 'text',
          '-webkit-text-fill-color': 'transparent',
          fontSize: '42px',
          fontWeight: 700,
        }}
      >
        Oops, something went wrong!
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <Typography
          sx={{
            backgroundImage:
              'linear-gradient(0deg, rgb(222, 204, 229) 0%, rgb(240, 194, 195) 100%)',
            backgroundClip: 'text',
            '-webkit-text-fill-color': 'transparent',
            fontSize: '22px',
            fontWeight: 700,
          }}
        >
          Please, try again later, or contact us.
        </Typography>
        <Link href={'https://discord.com/channels/1052434442653810769/1052434442653810775'}>
          <Button
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
              color: 'white',
              fontWeight: 600,
              fontSize: '16px',
              padding: '10px 20px',
              borderRadius: '10px',
              width: 'auto',
              border: '1px solid rgba(255, 255, 255, 0.12)',
            }}
          >
            Contact us
          </Button>
        </Link>
      </Box>
    </Box>
  )
}
